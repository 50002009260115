var App = (function()
{
	var animationWOW = {};
	var viewport = {};
	var base = '';

	var _getBaseURL = function()
	{
		var bases = document.getElementsByTagName('base');
		var baseHref = '';

		if(bases.length>0)
		{
			baseHref = bases[0].href;
		}

		return baseHref;
	};

	var _getViewport = function()
	{
		viewport = {
			width  : jQuery(window).width(),
			height : jQuery(window).height()
		};
	};

	var _ajaxRequest = function(ajaxMethod,ajaxUrl,ajaxTarget,ajaxType,ajaxParams,ajaxLoader,ajaxInit)
	{
		$.ajax(
		{
			type: ajaxMethod,
			url: ajaxUrl,
			data: ajaxParams,
			dataType: ajaxType,
			async: true,
			beforeSend: function()
			{
				if(typeof(ajaxLoader)!=='undefined' && ajaxLoader===1)
				{
				}
			},
			success: function(data)
			{
				if(typeof(ajaxLoader)!=='undefined' && ajaxLoader===1)
				{
				}
				if(ajaxTarget!=='')
				{
					if(ajaxType==='html')
					{
						$('#'+ajaxTarget).html(data);
					}
					else if(ajaxType==='json')
					{
						$('#'+ajaxTarget).html(data.RESULT);
					}
					else if(ajaxType==='text')
					{
						$('#'+ajaxTarget).val(data);
					}
				}
				if(typeof(ajaxInit)!=='undefined')
				{
					switch(ajaxInit)
					{
						case 1: // About
							var clientHeight = $(window).height();
							$('.aboutBgr').css('height',clientHeight);

							App.animationClick('.about .person_2','flipInY');
							App.animationClick('.aboutUsPage .logo','flipInY');

							if($(window).width()>'641')
							{
								 $('.paralax').parallax('updateLayers');
							}

							break;
						case 2: // Portfolio
							if($(window).width()>'641')
							{
								 $('.paralax').parallax('updateLayers');
							}
							
							App.animation('','pws_animate');

							if($('#portfolioCarousel.carousel').length)
							{
								$('#portfolioCarousel.carousel').carousel(
								{
									interval: 10000
								});

								$("#portfolioCarousel.carousel").on('slide.bs.carousel', function(evt)
								{
									var step = $(evt.relatedTarget).index();
									$('#portfolio_captions .carousel-caption:not(#caption-'+step+')').hide('fast', function()
									{
										$('#caption-'+step).show();
									});
								});
							}

							break;
					}
				}
			},
			error: function(data,textStatus)
			{
				if(typeof(ajaxLoader)!=='undefined' && ajaxLoader===1)
				{
				}
				if(ajaxTarget!=='')
				{
					if(ajaxType==='html' || ajaxType==='json')
					{
						$('#'+ajaxTarget).html('Грешка при изпълнението на заявката!');
					}
					else if(ajaxType==='text')
					{
						$('#'+ajaxTarget).val('Грешка при изпълнението на заявката!');
					}
				}
			}
		});
	};

	var _handleForms = function(exclude)
	{
		var suffix = '';

		if(typeof(exclude)!=='undefined')
		{
			suffix = ":not("+exclude+")";
		}

		if(jQuery("form"+suffix).length)
		{
			if(typeof jQuery("form"+suffix).data('lang')!=='undefined' && (parseInt(jQuery("form"+suffix).data('lang'))===1 || jQuery("form"+suffix).data('lang')==='bg'))
			{
				jQuery.extend(jQuery.validator.messages, {
					required: "Полето е задължително.",
					remote: "Моля, въведете правилната стойност.",
					email: "Моля, въведете валиден email.",
					url: "Моля, въведете валидно URL.",
					date: "Моля, въведете валидна дата.",
					dateISO: "Моля, въведете валидна дата (ISO).",
					number: "Моля, въведете валиден номер.",
					digits: "Моля, въведете само цифри",
					creditcard: "Моля, въведете валиден номер на кредитна карта.",
					equalTo: "Моля, въведете същата стойност отново.",
					accept: "Моля, въведете стойност с валидно разширение.",
					maxlength: jQuery.validator.format("Моля, въведете повече от {0} символа."),
					minlength: jQuery.validator.format("Моля, въведете поне {0} символа."),
					minWords: jQuery.validator.format("Моля, въведете поне {0} думи."),
					rangelength: jQuery.validator.format("Моля, въведете стойност с дължина между {0} и {1} символа."),
					range: jQuery.validator.format("Моля, въведете стойност между {0} и {1}."),
					max: jQuery.validator.format("Моля, въведете стойност по-малка или равна на {0}."),
					min: jQuery.validator.format("Моля, въведете стойност по-голяма или равна на {0}.")
				});
			}

			jQuery("form"+suffix).each(function(index,element)
			{
				jQuery(this).validate(
				{
					/*highlight: function(element,errorClass)
					{
						jQuery(element).closest('.has-feedback').removeClass('has-success').addClass('has-error');
					},
					unhighlight: function(element,errorClass)
					{
						jQuery(element).closest('.has-feedback').removeClass('has-error').addClass('has-success');
						if(jQuery(element).closest('.has-feedback').find('span.help-block').length>0)
						{
							jQuery(element).closest('.has-feedback').find('span.help-block').remove();
						}
					},
					errorClass: 'help-block help-style text-right',*/
					errorElement: 'span',
					errorPlacement: function(error,element)
					{
						if (element.parent('.switch').length)
						{
							element.parent('.switch').next('.switch_label').next('span.error').remove();
							error.insertAfter(element.parent('.switch').next('.switch_label'));
						}
						else
						{
							element.next('span.error').remove();
							error.insertAfter(element);
						}
					}
				});
			});

			if(jQuery('.csc').length)
			{
				jQuery('.csc').click(function()
				{
					var arr_src;

					if(jQuery('.csc > img').attr('src').search('&')!==-1)
					{
						arr_src = jQuery('.csc > img').attr('src').split('&r=');
						jQuery('.csc > img').attr('src',(arr_src[0]+'&r='+Math.floor((Math.random()*100000)+1)));
					}
					else
					{
						arr_src = jQuery('.csc > img').attr('src').split('?');
						jQuery('.csc > img').attr('src',(arr_src[0]+'?'+Math.floor((Math.random()*100000)+1)));
					}
				});
			}

			if(jQuery("form"+suffix+" .numbers").length)
			{
				jQuery("form"+suffix+" .numbers").keydown(function(e)
				{
					// Allow: backspace, delete, tab, escape, enter and .
					if(jQuery.inArray(e.keyCode,[46, 8, 9, 27, 13, 110, 190])!==-1 ||
					// Allow: Ctrl+A, Command+A
					(e.keyCode===65 && (e.ctrlKey===true || e.metaKey===true)) ||
					// Allow: home, end, left, right, down, up
					(e.keyCode>=35 && e.keyCode<=40))
					{
						// let it happen, don't do anything
						return;
					}

					// Ensure that it is a number and stop the keypress
					if((e.shiftKey || (e.keyCode<48 || e.keyCode>57)) && (e.keyCode<96 || e.keyCode>105))
					{
						e.preventDefault();
					}
				});
			}

			if(jQuery("form"+suffix+" .digits").length)
			{
				jQuery("form"+suffix+" .digits").keydown(function(e)
				{
					// Allow: backspace, delete, tab, escape, enter and .
					if(jQuery.inArray(e.keyCode,[46, 8, 9, 27, 13, 110])!==-1 ||
					// Allow: Ctrl+A, Command+A
					(e.keyCode===65 && (e.ctrlKey===true || e.metaKey===true)) ||
					// Allow: home, end, left, right, down, up
					(e.keyCode>=35 && e.keyCode<=40))
					{
						// let it happen, don't do anything
						return;
					}

					// Ensure that it is a number and stop the keypress
					if((e.shiftKey || (e.keyCode<48 || e.keyCode>57)) && (e.keyCode<96 || e.keyCode>105))
					{
						e.preventDefault();
					}
				});
			}
		}
	};

	var _handleFilters = function()
	{
		if($('#frm_filter').length)
		{
			$('#frm_filter').on('submit',function()
			{
				var category = $('#category').val();
				var brand = $('#brand').val();
				var size = $('#size').val();
				var color = $('#color').val();
				var from = $('#from').val();
				var to = $('#to').val();

				if(category>0 || brand>0 || size>0 || color>0 || from>0 ||to>0)
				{
					var space = '-';
					var delimiter = '_';

					if(typeof($(this).data('space'))!=='undefined')
					{
						space = $(this).data('space');
					}

					if(typeof($(this).data('delimiter'))!=='undefined')
					{
						delimiter = $(this).data('delimiter');
					}

					var re = new RegExp(delimiter+"filters"+space+"[0-9]+"+space+"[0-9]+"+space+"[0-9]+"+space+"[0-9]+"+space+"[0-9]+"+space+"[0-9]+","i");
					var url = window.location.href.replace(re,"");

					url = url+delimiter+'filters'+space+encodeURIComponent(category)+space+encodeURIComponent(brand)+space+encodeURIComponent(size)+space+encodeURIComponent(color)+space+encodeURIComponent(parseInt(from))+space+encodeURIComponent(parseInt(to));
//					console.log(url);

					window.location.replace(url);
				}

				return false;
			});
		}

		if($('#frm_search').length)
		{
			$('#frm_search').on('submit',function()
			{
				var url = $(this).prop('action');
				var search_text = $('#search_text').val();

				if(search_text)
				{
					var space = '-';
					var delimiter = '_';

					if(typeof($(this).data('space'))!=='undefined')
					{
						space = $(this).data('space');
					}

					if(typeof($(this).data('delimiter'))!=='undefined')
					{
						delimiter = $(this).data('delimiter');
					}

					url += delimiter+encodeURIComponent(search_text);
//					url += delimiter+search_text;
//					console.log(url);

					window.location.replace(url);
				}

				return false;
			});
		}
	};

	var _handleDialogs = function()
	{
		if($('#loginModal').length)
		{
			if($('#registerModal').length && $('#register-trigger').length)
			{
				$("#register-trigger").click(function()
				{
					$('#loginModal').modal('hide');
					$('#registerModal').modal('show');
				});
			}

			if($('#forgotModal').length && $('#forgot-trigger').length)
			{
				$("#forgot-trigger").click(function()
				{
					$('#loginModal').modal('hide');
					$('#forgotModal').modal('show');
				});
			}
		}

		if($('#registerModal').length)
		{
			if($('#loginModal').length && $('#login-trigger').length)
			{
				$("#login-trigger").click(function()
				{
					$('#registerModal').modal('hide');
					$('#loginModal').modal('show');
				});
			}
		}

		if($('#forgotModal').length)
		{
			if($('#loginModal').length && $('#login-trigger').length)
			{
				$("#login-trigger").click(function()
				{
					$('#forgotModal').modal('hide');
					$('#loginModal').modal('show');
				});
			}

			if($('#registerModal').length && $('#register-trigger').length)
			{
				$("#register-trigger").click(function()
				{
					$('#forgotModal').modal('hide');
					$('#registerModal').modal('show');
				});
			}
		}
	};

	var _handleImages = function()
	{
		if($('.shopProductImgContainer > img').length)
		{
			$('.shopProductImg').zoom();

			if($('.shopProductAdditionalImg a.productImage').length)
			{
				var $main = $('.shopProductImg');
				$('.shopProductAdditionalImg a.productImage').on('click',function(e)
				{
					e.preventDefault();

					$main.prop('src',$(this).prop('href'));
					$('.shopProductImg').trigger('zoom.destroy').zoom();
				});
			}
		}
	};

	var _handlePaymentForm = function(selector)
	{
		if(jQuery('form'+selector).length)
		{
			setTimeout(function()
			{
				jQuery('form'+selector).submit();
			}, 3000);
		}
	};

	return {
		init: function()
		{
			base = _getBaseURL();

			_handleForms('.search, .form-newsletter, .form-promo');
			//_handleFilters();
			//_handleDialogs();
//			_handleImages();
			_handlePaymentForm('#form_payment');
		},
		animation: function(index,box_class)
		{
			if(index!=='')
			{
				animationWOW[index] = new WOW(
				{
					boxClass: box_class
				});

				animationWOW[index].init();
			}
			else
			{
				var animation = new WOW(
				{
					boxClass: box_class
				});

				animation.init();
			}
		},
		animationHover: function(element,animation)
		{
			element = $(element);
			element.hover(
				function()
				{
					element.addClass('animated '+animation);
				},
				function()
				{
					//wait for animation to finish before removing classes
					window.setTimeout(function()
					{
						element.removeClass('animated '+animation);
					},2000);
				}
			);
		},
		animationClick: function(element,animation)
		{
			element = $(element);
			element.click(
				function()
				{
					element.addClass('animated '+animation);

					//wait for animation to finish before removing classes
					window.setTimeout(function()
					{
						element.removeClass('animated '+animation);
					},2000);
				}
			);
		},
		getWidth: function()
		{
			if($.isEmptyObject(viewport))
			{
				_getViewport();
			}
			return viewport.width;
		},
		getHeight: function()
		{
			if($.isEmptyObject(viewport))
			{
				_getViewport();
			}
			return viewport.height;
		},
		loadTeamPerson: function(page,id)
		{
			_ajaxRequest('POST','ajax.php','paralax','html','act=2&page='+page+'&id='+id,0,1);
		},
		loadGalleryCategory: function(page,id)
		{
			_ajaxRequest('POST','ajax.php','portfolio','html','act=3&page='+page+'&id='+id,0,2);
		},
		scrollDown: function(elem, value, duration, animation)
		{
			var selector = '#'+elem;

			if(elem.indexOf('.')===0 || elem.indexOf('div')===0 || elem.indexOf('h4')===0)
			{
				selector = elem;
			}

			if($(selector).length>0)
			{
				// calculate destination place
				var dest = 0;
				var offset = ((typeof(value)!=="undefined") ? value : 0);

				if($(selector).offset().top>$(document).height()-$(window).height())
				{
					dest = ($(document).height()-$(window).height())-offset;
				}
				else
				{
					dest = $(selector).offset().top-offset;
				}

				if(typeof(animation)==='undefined')
				{
					animation = 'swing';
				}

				// go to destination
				$('html,body').animate({scrollTop:dest}, duration, animation);
			}
		},
		scrollTop: function(duration, animation)
		{
			var dest = 0;

			if(typeof(animation)==='undefined')
			{
				animation = 'swing';
			}

			// go to destination
			$('html,body').animate({scrollTop:dest}, duration, animation);
		},
		setFilters: function(type,value)
		{
			switch(type)
			{
				case 1: // Category
					$('#category').val(value);
					$('li[id^=category]').removeClass('active');
					$('li#category'+value).addClass('active');
					break;
				case 2: // Brand
					$('#brand').val(value);
					$('li[id^=brand]').removeClass('active');
					$('li#brand'+value).addClass('active');
					break;
				case 3: // Size
					$('#size').val(value);
					$('li[id^=size]').removeClass('active');
					$('li[id^=size]').each(function()
					{
						$(this).addClass('inActive');
					});
					$('li#size'+value).removeClass('inActive');
					$('li#size'+value).addClass('active');
					break;
				case 4: // Color
					$('#color').val(value);
					$('li[id^=color]').removeClass('active');
					$('li#color'+value).addClass('active');
					break;
				case 5: // Price from
					$('#from').val(value);
					break;
				case 6: // Price to
					$('#to').val(value);
					break;
			}
		},
		toggleFilters: function(action)
		{
			if(action===1) // Open
			{
				$('#show_filters').addClass('hide');
				$('#hide_filters').removeClass('hide');
			}

			$('#filters').slideToggle(
			{
				duration: 'slow',
				start: function()
				{
					$('#filters').children().not('img').hide();
				},
				complete: function()
				{
					$('#filters').children().not('img').show();

					if(action!==1) // Close
					{
						$('#hide_filters').addClass('hide');
						$('#show_filters').removeClass('hide');
					}
				}
			});
		},
		initImagesList: function(selector)
		{
			if($(selector).length)
			{
				$(selector).flexisel(
				{
					visibleItems: 3, //  The initial number of visible items in the carousel
					animationSpeed: 500, // Speed of the scrolling animation
					autoPlay: false, // Auto scrolling
					autoPlaySpeed: 3000, // Speed of auto scrolling
					pauseOnHover: true, // Whether or not pause the auto sliding on mouse over
					multipleSlides: true, // Use more than one slide while scrolling (scroll by visibleItems-slides)
					enableResponsiveBreakpoints: true, // Enable responsive breakpoints
					responsiveBreakpoints: {
						feature: {
							changePoint:320,
							visibleItems: 2
						},
						portrait: {
							changePoint:480,
							visibleItems: 2
						},
						landscape: {
							changePoint:640,
							visibleItems: 2
						},
						tablet: {
							changePoint:769,
							visibleItems: 3
						},
						nexusatablet: {
							changePoint:801,
							visibleItems: 2
						},
						desktop: {
							changePoint:992,
							visibleItems: 3
						}
					}
				});
			}
		},
        initShopCategoriesHomeList: function(selector)
		{
			if($(selector).length)
			{
				$(selector).flexisel(
				{
					visibleItems: 4, //  The initial number of visible items in the carousel
					animationSpeed: 500, // Speed of the scrolling animation
					autoPlay: false, // Auto scrolling
					autoPlaySpeed: 3000, // Speed of auto scrolling
					pauseOnHover: true, // Whether or not pause the auto sliding on mouse over
					multipleSlides: false, // Use more than one slide while scrolling (scroll by visibleItems-slides)
					enableResponsiveBreakpoints: true, // Enable responsive breakpoints
					responsiveBreakpoints: {
						feature: {
							changePoint:320,
							visibleItems: 1
						},
						portrait: {
							changePoint:480,
							visibleItems: 1
						},
						landscape: {
							changePoint:640,
							visibleItems: 2
						},
						tablet: {
							changePoint:769,
							visibleItems: 2
						},
						nexusatablet: {
							changePoint:801,
							visibleItems: 2
						},
						desktop: {
							changePoint:1025,
							visibleItems: 3
						}
					}
				});
			}
		},
		initPriceSlider: function(selector)
		{
			if($(selector).length)
			{
				var $priceSlider = $(selector);

				var range_min = 100;
				var range_max = 375;
				var start_min = range_min;
				var start_max = range_max;

				if(typeof($priceSlider.data('min'))!=='undefined')
				{
					range_min = $priceSlider.data('min');
				}

				if(typeof($priceSlider.data('max'))!=='undefined')
				{
					range_max = $priceSlider.data('max');
				}

				if(typeof($priceSlider.data('from'))!=='undefined')
				{
					start_min = $priceSlider.data('from');
				}

				if(typeof($priceSlider.data('to'))!=='undefined')
				{
					start_max = $priceSlider.data('to');
				}

				if(range_min===range_max)
				{
					range_min = 0;
				}

				if(parseInt(start_min)===0)
				{
					start_min = range_min;
				}

				if(parseInt(start_max)===0)
				{
					start_max = range_max;
				}

				if(start_min===start_max)
				{
					start_min = range_min;
				}

				noUiSlider.create($priceSlider.get(0), {
					start: [ start_min, start_max ],
					step: 10,
					behaviour: 'drag-tap',
					connect: true,
					range: {
						'min': [range_min],
						'max': range_max
					},
					pips: {
						mode: 'count',
						values: 5,
						density: 4,
						stepped: true
					}
				});

				var handles = $priceSlider.find('.noUi-handle'),
					tooltips = [];

				// Add divs to the slider handles.
				handles.each(function(index,element)
				{
					tooltips[index] = $(document.createElement('div'));
					tooltips[index].addClass('noUI-tooltip');
					tooltips[index].html('<strong></strong>');

					$(this).append(tooltips[index]);
				});

				// When the slider changes, write the value to the tooltips.
				$priceSlider.get(0).noUiSlider.on('update',function(values,handle)
				{
					tooltips[handle].html('<strong>'+values[handle]+'</strong>');
				});

				$priceSlider.get(0).noUiSlider.on('set',function(values,handle)
				{
					App.setFilters(5,values[0]);
					App.setFilters(6,values[1]);
				});
			}
		},
		initTouchSpin: function(selector)
		{
			if($(selector).length)
			{
				$(selector).TouchSpin(
				{
					min: 1,
					max: 100,
					step: 1,
					decimals: 0,
					boostat: 5,
					maxboostedstep: 10,
					mousewheel: false,
					buttondown_class: 'btn btn-inverse-cart',
					buttonup_class: 'btn btn-inverse-cart'
				}).on("touchspin.on.stopspin",function()
				{
					if($(this).parent().find('span.btn-link-cart').hasClass('invisible'))
					{
						$(this).parent().find('span.btn-link-cart').removeClass('invisible');
					}
				}).on("change",function()
				{
					var index = $(this).prop('id').replace(/(\w+)_([0-9A-Fa-f]{32})/,"$2");
					var value = $(this).val();

					var $index = $("input[name='cartIndex["+index+"]']");

					if(parseInt(value)>0 && $index.length)
					{
						var arr_data = $index.val().split('|');
						var main = arr_data[0];
						var id = arr_data[1];

						if(!isNaN(main) && parseInt(main)>0 && !isNaN(id) && parseInt(id)>0)
						{
							Cart.update(main,id,index);
						}
					}
				});
			}
		},
		initSwitch: function(selector)
		{
			if($(selector).length)
			{
				$(selector).click(function()
				{
					var $this = $(this);

					$this.find('.btn').toggleClass('active');

					if($this.find('.btn-primary').length>0)
					{
						$this.find('.btn').toggleClass('btn-primary');
					}

					if($this.find('.btn-danger').length>0)
					{
						$this.find('.btn').toggleClass('btn-danger');
					}

					if($this.find('.btn-success').length>0)
					{
						$this.find('.btn').toggleClass('btn-success');
					}

					if($this.find('.btn-info').length>0)
					{
						$this.find('.btn').toggleClass('btn-info');
					}

					$this.find('.btn').toggleClass('btn-default');

					var $button = $this.find('.btn.active');
					var value = $button.data('value');
					var id = $button.prop('id').replace(/([0-9]+)$/ig,'');

					$('#'+id).val(value);

					App.changeImage('#profilePhoto',value);
					App.changeImage('#achievementImage',value);
//					App.switchImage('#profilePhoto');
				});
			}
		},
		initSelect: function(selector)
		{
			if($(selector).length)
			{
				$(selector).selectpicker(
				{
					container: 'body',
					width: '100%',
					size: 6
				});
			}
		},
		initDateTime: function(selector)
		{
			if($(selector).length)
			{
				var start_date = moment().startOf('day').add(1,'days');
				var $form = $(selector).closest('form');
				var tooltips = {
					today: 'Go to today',
					clear: 'Clear selection',
					close: 'Close the picker',
					selectMonth: 'Select Month',
					prevMonth: 'Previous Month',
					nextMonth: 'Next Month',
					selectYear: 'Select Year',
					prevYear: 'Previous Year',
					nextYear: 'Next Year',
					selectDecade: 'Select Decade',
					prevDecade: 'Previous Decade',
					nextDecade: 'Next Decade',
					prevCentury: 'Previous Century',
					nextCentury: 'Next Century',
					pickHour: 'Pick Hour',
					incrementHour: 'Increment Hour',
					decrementHour: 'Decrement Hour',
					pickMinute: 'Pick Minute',
					incrementMinute: 'Increment Minute',
					decrementMinute: 'Decrement Minute',
					pickSecond: 'Pick Second',
					incrementSecond: 'Increment Second',
					decrementSecond: 'Decrement Second',
					togglePeriod: 'Toggle Period',
					selectTime: 'Select Time'
				};

				if(typeof $form.data('lang')!=='undefined' && (parseInt($form.data('lang'))===1 || $form.data('lang')==='bg'))
				{
					tooltips = {
						today: 'Днешна дата',
						clear: 'Изчистване на избраното',
						close: 'Затваряне на календара',
						selectMonth: 'Изберете месец',
						prevMonth: 'Предходен месец',
						nextMonth: 'Следващ месец',
						selectYear: 'Изберете година',
						prevYear: 'Предходна година',
						nextYear: 'Следваща година',
						selectDecade: 'Изберете десетилетие',
						prevDecade: 'Предходно десетилетие',
						nextDecade: 'Следващо десетилетие',
						prevCentury: 'Предходен век',
						nextCentury: 'Следващ век',
						pickHour: 'Изберете час',
						incrementHour: 'Увеличаване',
						decrementHour: 'Намаляване',
						pickMinute: 'Изберете минути',
						incrementMinute: 'Увеличаване',
						decrementMinute: 'Намаляване',
						pickSecond: 'Изберете секунди',
						incrementSecond: 'Увеличаване',
						decrementSecond: 'Намаляване',
						togglePeriod: 'Превключване на периода',
						selectTime: 'Изберете време'
					};
				}

				$(selector).datetimepicker(
				{
					locale: 'bg',
					format: 'DD.MM.YYYY HH:mm',
					stepping: 30,
					minDate: start_date,
					enabledHours: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
					ignoreReadonly: true,
					sideBySide: true,
					showClose: true,
					icons: {
						time: "fa fa-clock-o",
						date: "fa fa-calendar",
						up: "fa fa-arrow-up",
						down: "fa fa-arrow-down"
					},
					tooltips: tooltips
				});
			}
		},
		initCartCount: function(selector)
		{
			if($(selector).length)
			{
				$(document).on("focusout",selector,function()
				{
					var index = $(this).prop('id').replace(/(\w+)_([0-9A-Fa-f]{32})/,"$2");
					var value = $(this).val();

					var $index = $("input[name='cartIndex["+index+"]']");

					if(parseInt(value)>0 && $index.length)
					{
						var arr_data = $index.val().split('|');
						var main = arr_data[0];
						var id = arr_data[1];

						if(!isNaN(main) && parseInt(main)>0 && !isNaN(id) && parseInt(id)>0)
						{
							Cart.update(main,id,index);
						}
					}
				});
			}
		},
		initImagePreview: function(selector)
		{
			if($(selector).length)
			{
				$(selector).each(function(index,element)
				{
					var $this = $(this);

					$this.on('click',function(e)
					{
						e.preventDefault();

						var title = 'Изглед';

						if(typeof($this.prop('alt'))!=='undefined' && $this.prop('alt')!=='')
						{
							title = $this.prop('alt');
						}

						$('#dlgModal').on('show.bs.modal',function()
						{
							var $dialog = $(this);

							if($dialog.find('.modal-title').length)
							{
								$dialog.find('.modal-title').html(title);
							}

							if($dialog.find('.modal-body').length)
							{
								$dialog.find('.modal-body').html('<img src="'+$this.prop('src')+'" alt="'+title+'">');
							}
						}).on('hidden.bs.modal',function()
						{
							var $dialog = $(this);

							if($dialog.find('.modal-title').length)
							{
								$dialog.find('.modal-title').html('');
							}

							if($dialog.find('.modal-body').length)
							{
								$dialog.find('.modal-body').html('');
							}
						});

						$('#dlgModal').modal(
						{
							show:true
						});
					});
				});
			}
		},
		initLightbox: function(selector)
		{
			$(document).on('click',selector,function(e)
			{
				e.preventDefault();

				$(this).ekkoLightbox();
			});
		},
		initTooltips: function()
		{
			if($('[data-toggle="tooltip"]').length)
			{
				$('[data-toggle="tooltip"]').tooltip();
			}

			if($('.tooltips').length)
			{
				$('.tooltips').tooltip();
			}
		},
		initScrollDownButton: function(selector)
		{
			if($(selector).length > 0)
			{
				$(selector).click(function()
				{
					var scrollDownTo = $(this).parent().next().attr('id');

					if(scrollDownTo)
					{
						App.scrollDown(scrollDownTo,0,600);
					}
				});
			}
		},
		initFiltersButtons: function(filters,button_show,button_hide)
		{
			if($(button_hide).length)
			{
				$(button_hide).click(function()
				{
					$(filters).slideUp();
					$(button_show).show(500);
				});
			}

			if($(button_show).length)
			{
				$(button_show).click(function()
				{
					$(filters).slideDown();
					$(this).hide(500);
				});
			}
		},
		initShareLinkDialog: function()
		{
			if($('#dlgModal').length && $('#dlg-title').length && $('#dlg-content').length && $('a.share[data-toggle=modal]').length)
			{
				$('#dlgModal .modal-title').html($('#dlg-title').html());
				$('#dlgModal .modal-body').html($('#dlg-content').html());
				$('#dlg-title').remove();
				$('#dlg-content').remove();

				$("input.share-link[type='text']").on('mouseup',function()
				{
					$(this).select();
				});
			}
		},
		changePaymentMethod: function(kind)
		{
			var page = $('#cartPageID').val();
			var type = 0;
			var id = 1;

			$('.paymentMethod').removeClass('active');

			if($('#cartPaymentMethod'+kind).length)
			{
				$('#cartPaymentMethod'+kind).closest('.paymentMethod').addClass('active');
			}

			if($('#cartTotalPrice').length && $('#cartTotalCurrency').length)
			{
				type = $('#cartTotalPrice').val();
				id = $('#cartTotalCurrency').val();
			}

			_ajaxRequest('POST','ajax.php','paymentProceed','html','act=70&page='+page+'&kind='+kind+'&type='+type+'&id='+id,0,0);
		},
		initFavorites: function(selector,menu)
		{
			if($(selector).length)
			{
				$(document).on('click',selector,function(e)
				{
					e.preventDefault();

					var $this = $(this);
					var type,
						id,
						status;

					if(typeof($this.data('type'))!=='undefined')
					{
						type = parseInt($this.data('type'));
					}

					if(typeof($this.data('id'))!=='undefined')
					{
						id = parseInt($this.data('id'));
					}

					if(typeof($this.data('status'))!=='undefined')
					{
						status = parseInt($this.data('status'));
					}

					if(type>0 && id>0)
					{
						$.ajax(
						{
							type: 'POST',
							url: 'ajax.php',
							data: 'act=65&kind=1&type='+type+'&id='+id+'&status='+status,
							dataType: 'json',
							async: true,
							beforeSend: function()
							{
							},
							success: function(data)
							{
								var result = parseInt(data.result);

								if(result===1)
								{
									var icon_class = 'favoritesIcon';

									if(parseInt(data.status)===0)
									{
										icon_class += ' active';
									}

									$this.children().removeClass('favoritesIcon active').addClass(icon_class);

									$this.data('status',data.status);

									if(data.tooltip.length && typeof($this.attr('data-original-title'))!=='undefined')
									{
										$this.attr('data-original-title', data.tooltip).tooltip('show');
									}

									if($(menu).length)
									{
										icon_class = 'favoritesIcon';

										if(parseInt(data.count)>0)
										{
											icon_class += ' active';
										}

										$(menu).find('i').removeClass('favoritesIcon active').addClass(icon_class);

/*										if(parseInt(data.count)>0)
										{
											$(menu).find('i').addClass('pulse pws_animate').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',function()
											{
												$(this).removeClass('pulse pws_animate');
											});
										}*/
									}
								}
							},
							error: function(data,textStatus)
							{
							}
						});
					}
				});
			}
		},
		initAutoGrow: function(selector)
		{
			if($(selector).length)
			{
				$(selector).autoGrowInput(
				{
					minWidth: 180,
					maxWidth: 320,
					comfortZone: 10
				});
			}
		},
		showAlert: function(type, title, text, settings, functions)
		{
			var options = {
				title: title,
				text: text,
				type: ((type==='success' || type===1) ? 'success' : ((type==='error' || type==='danger' || type===2) ? 'error' : ((type==='warning' || type===3) ? 'warning' : ((type==='question' || type===4) ? 'question' : 'info'))))
			};

			if(typeof(settings)==='object')
			{
				$.extend(options, settings);
			}

			if(typeof(functions)==='object')
			{
				swal(options).then(
					function() // On confirm
					{
						if(typeof(functions.confirm)==='function')
						{
							functions.confirm(this);
						}
					},
					function(dismiss) // On cancel/dismiss (dismiss can be 'cancel', 'overlay', 'close' and 'timer')
					{
						if(typeof(functions.cancel)==='function')
						{
							functions.cancel(this, dismiss);
						}
					}
				);
				
			}
			else if(typeof(options.timer)!=='undefined' && parseInt(options.timer)>0)
			{
				swal(options).then(
					function() // On confirm
					{},
					function(dismiss) // On cancel/dismiss (dismiss can be 'cancel', 'overlay', 'close' and 'timer')
					{}
				);
			}
			else
			{
				swal(options);
			}
		},
		showToastr: function(type, text, title, settings)
		{
			var kind = ((type==='success' || type===1) ? 'success' : ((type==='error' || type==='danger' || type===2) ? 'error' : ((type==='warning' || type===3) ? 'warning' : 'info')));
			var options = {
				"closeButton": false,
				"debug": false,
				"newestOnTop": false,
				"progressBar": true,
				"positionClass": "toast-top-right",
				"preventDuplicates": false,
				"onclick": null,
				"showDuration": "300",
				"hideDuration": "1000",
				"timeOut": "5000",
				"extendedTimeOut": "1000",
				"showEasing": "swing",
				"hideEasing": "linear",
				"showMethod": "fadeIn",
				"hideMethod": "fadeOut"
			};

			if(typeof(settings)==='object')
			{
				$.extend(options, settings);
			}

			toastr.options = options;

			if(typeof(title)!=='undefined' && title.length)
			{
				toastr[kind](text, title);
			}
			else
			{
				toastr[kind](text)
			}
		},
		initMessage: function(selector, kind)
		{
			if($(selector).length)
			{
				if($('body > div.preloader:not(.loaded)').length)
				{
					var interval = setInterval(function()
					{
						if($('body > div.preloader').hasClass('loaded'))
						{
							clearInterval(interval);
							App.showMessage(selector, kind);
						}
					},1000);
				}
				else
				{
					App.showMessage(selector, kind);
				}
			}
		},
		showMessage: function(selector, kind)
		{
			if($(selector).length)
			{
				$(selector).each(function()
				{
					var $this = $(this);
					var $main = $this.children('div');
					var $sub = ($main.length ? $main.children('strong, span') : null);
					var type = '';
					var title = '';
					var text = '';

					if($this.hasClass('hide'))
					{
						if($main.length && $main.text().length)
						{
							text = $main.clone().children('strong, span').remove().end().text();

							$.each($main.attr('class').split(/\s+/), function(i, name)
							{
								if(name.indexOf('success')>-1)
								{
									type = 'success';
									return false;
								}
								else if(name.indexOf('danger')>-1)
								{
									type = 'danger';
									return false;
								}
								else if(name.indexOf('warning')>-1)
								{
									type = 'warning';
									return false;
								}
								else if(name.indexOf('info')>-1)
								{
									type = 'info';
									return false;
								}
							});

							if($sub.length && $sub.text().length)
							{
								title = $sub.text();
							}
						}

						if(type!=='' && text!=='')
						{
							var settings;

							if(typeof(kind)==='undefined' || parseInt(kind)<1 || parseInt(kind)>2)
							{
								kind = 1;
							}

							if(kind===1)
							{
								settings = {
									timer: 5000,
									showCloseButton: false,
									showCancelButton: false
								};

								App.showAlert(type, title, text, settings);
							}
							else
							{
								settings = {
									"timeOut": 5000,
									"progressBar": true
								};

								App.showToastr(type, text, title, settings);
							}
						}
						else
						{
							$this.removeClass('hide');
						}
					}
				});
			}
		},
		initCookieConsent: function()
		{
			jQuery.ajax(
			{
				type: 'POST',
				url: 'ajax.php',
				data: 'act=10&kind=1&type=1',
				dataType: 'json',
				async: true,
				beforeSend: function() {},
				success: function(data)
				{
					if (typeof(data) === 'object')
					{
						window.cookieconsent.initialise(
						{
							"cookie": data.cookie,
							"content": data.content,
							onStatusChange: function(status)
							{
								//console.log(status);
							}
						});
					}
				},
				error: function(data, textStatus)
				{
				}
			});
		}
	};
})();