jQuery(document).ready(function($)
{
	// Initialize the preloader
    //Preload.init();

	jQuery(window).on("load", function(e)
	{
		// Start image preloading
		//Preload.run();

		// Start main animations
		//App.animation('','pws_animate');

		// Initialize the application class
		App.init();

		// Initialize the sliders
		//Slider.init('#sliders.sliders_section');
		//Slider.init('#testimonials.sliders_section');

		// Cookie Policy
		App.initCookieConsent();
	});

	// Home categories carousel
	//App.initShopCategoriesHomeList('#shopCategoriesHomeList');

    // Additional product images
	//App.initImagesList('#shopProductAdditionalImg');

	// Price slider
	//App.initPriceSlider('#priceSlider');

	// Count spinner
	//App.initTouchSpin('input.touchspin');

	// Select box
//	App.initSelect('.selectpicker');

	// Datetime picker
//	App.initDateTime('.datetimepicker');

	// Birthday select
//	App.initBirthdaySelect();

	// Auto-size input
//	App.initAutoGrow('.autogrow');

	// Initialize tooltips
	//App.initTooltips();

	// Lightbox preview
	//App.initLightbox('*[data-toggle="lightbox"]');

	// Initialize scroll down button
	//App.initScrollDownButton('.scrollDown');

	// Initialize filters block show and hide buttons
	//App.initFiltersButtons('#filters','#buttonFilterShow','#arrowUpFilter');

	// Initialize share link dialog
	//App.initShareLinkDialog();

	// Initialize favorites links
	//App.initFavorites('.btn-favorite','#top-favorites');

	// Payment method change
	/*if($('.paymentMethod').length)
	{
		$(document).on('change','.paymentMethod input[type="radio"]',function(ev)
		{
			App.changePaymentMethod($(this).val());
		});
	}

	if($('#paymentProceed').length && $('.paymentMethod input[type="radio"]:checked').length)
	{
		App.changePaymentMethod($('.paymentMethod input[type="radio"]:checked').val());
	}*/

	// Initialize custom notification messages
	//App.initMessage('#message', 2);
});
